import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../ApiMap";

const VideoNode = ({ video }) => {
  const { videoId, userName, categoryName, thumbnailUrl, likeCnt, dislikeCnt, viewCnt, title } = video;
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      // 조회수 증가 API 호출
      await api.incrementViewCount(videoId);

      // `/video/${videoId}`로 라우터 이동
      navigate(`/video/${videoId}`);
    } catch (error) {
      console.error("Error incrementing view count or navigating:", error);
    }
  };

  return (
    <div style={styles.container} onClick={handleClick}>
      {/* 상단 썸네일 */}
      <div style={styles.thumbnailContainer}>
        <img src={thumbnailUrl} alt={`${title} Thumbnail`} style={styles.thumbnail} />
      </div>

      {/* 하단 정보 */}
      <div style={styles.infoContainer}>
        <h2 style={styles.title}>{title}</h2>
        <p style={styles.category}>Category: {categoryName}</p>
        <p style={styles.user}>Uploaded by: {userName}</p>
        <div style={styles.stats}>
          <span>👍 {likeCnt}</span>
          <span>👎 {dislikeCnt}</span>
          <span>👁️ {viewCnt}</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
    width: "300px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    cursor: "pointer",
  },
  thumbnailContainer: {
    flex: "1",
    overflow: "hidden",
  },
  thumbnail: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  infoContainer: {
    padding: "10px",
    backgroundColor: "#f9f9f9",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0 0 10px",
  },
  category: {
    fontSize: "14px",
    color: "#555",
    margin: "0 0 5px",
  },
  user: {
    fontSize: "14px",
    color: "#777",
    margin: "0 0 10px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "#333",
  },
};

export default VideoNode;
