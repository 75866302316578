import React, { useState } from "react";
import VideoList from "./VideoList"; // VideoList 컴포넌트가 결과를 렌더링
import api from "../../ApiMap"; // API 요청을 관리하는 파일에서 import

const Search = () => {
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [videos, setVideos] = useState([]);
  const [isStart, setIsStart] = useState(true); // 현재 페이지가 첫 번째 페이지인지
  const [isEnd, setIsEnd] = useState(true); // 현재 페이지가 마지막 페이지인지

  // 검색 함수
  const handleSearch = async () => {
    try {
      const response = await api.searchVideos(keyword, page, 10);
      setVideos(response.data.content); // 검색 결과 리스트
      setIsStart(response.data.start); // 첫 페이지 여부
      setIsEnd(response.data.end); // 마지막 페이지 여부
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  // 페이지 이동 핸들러
  const handlePageChange = (direction) => {
    if (direction === "prev" && page > 0) {
      setPage((prev) => prev - 1); // 페이지 감소
    } else if (direction === "next" && !isEnd) {
      setPage((prev) => prev + 1); // 페이지 증가
    }
  };

  // 페이지 변경 후 검색을 다시 호출
  React.useEffect(() => {
    handleSearch();
  }, [page, keyword]); // 페이지나 키워드가 변경될 때마다 검색 호출

  return (
    <div style={styles.container}>
      {/* 검색 입력 및 버튼 */}
      <div style={styles.searchBar}>
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search videos..."
          style={styles.input}
        />
        <button onClick={handleSearch} style={styles.button}>
          Search
        </button>
      </div>

      {/* 검색 결과 리스트 */}
      <VideoList videos={videos} />

      {/* 페이지 네비게이션 */}
      <div style={styles.pagination}>
        <button
          onClick={() => handlePageChange("prev")}
          style={{ ...styles.pageButton, ...(isStart ? styles.disabled : {}) }}
          disabled={isStart}
        >
          Prev
        </button>
        <button
          onClick={() => handlePageChange("next")}
          style={{ ...styles.pageButton, ...(isEnd ? styles.disabled : {}) }}
          disabled={isEnd}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  },
  searchBar: {
    display: "flex",
    marginBottom: "20px",
  },
  input: {
    flex: 1,
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    marginRight: "10px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  pagination: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  pageButton: {
    padding: "10px 20px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "white",
  },
  disabled: {
    cursor: "not-allowed",
    backgroundColor: "#f0f0f0",
    color: "#999",
  },
};

export default Search;
