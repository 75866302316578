import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Login from "./component/Auth/Login";
import Dashboard from "./component/Dashboard";
import "bootstrap/dist/css/bootstrap.css";
import VideoAlone from "./component/VideoSerach/VideoAlone";
import UserInfo from "./component/User/UserInfo";

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!auth.isAuthenticated ? <Login /> : <Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={auth.isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />} />
        <Route
          path="/"
          element={auth.isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />}
        />
        <Route path="/video/:videoId" element={<VideoAlone />} />
        <Route path="/userInfo" element={<UserInfo />} />
      </Routes>
    </Router>
  );
}

export default App;
