import React, { useState } from "react";
import api from "../../../ApiMap";

const Comment = ({ comment, fetchReplies, replies, auth, setComments }) => {
  const [showReplies, setShowReplies] = useState(false); // 답글 표시 여부
  const [isEditing, setIsEditing] = useState(false); // 수정 모드 여부
  const [editContent, setEditContent] = useState(comment.content); // 수정 중인 댓글 내용

  const handleToggleReplies = async () => {
    if (!showReplies && !replies) {
      await fetchReplies(comment.commentId);
    }
    setShowReplies(!showReplies);
  };

  const handleUpdateComment = async () => {
    if (!editContent.trim()) return;

    try {
      const response = await api.updateComment(comment.commentId, {
        commentId: comment.commentId,
        content: editContent,
      });

      if (response.data) {
        setComments((prev) =>
          prev.map((c) => (c.commentId === comment.commentId ? { ...c, content: editContent } : c)),
        );
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDeleteComment = async () => {
    try {
      await api.deleteComment(comment.commentId);
      setComments((prev) => prev.filter((c) => c.commentId !== comment.commentId));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleLike = async () => {
    try {
      await api.updateLikeCount(comment.commentId, true);
      setComments((prev) =>
        prev.map((c) => (c.commentId === comment.commentId ? { ...c, likeCnt: c.likeCnt + 1 } : c)),
      );
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const handleDislike = async () => {
    try {
      await api.updateDislikeCount(comment.commentId, true);
      setComments((prev) =>
        prev.map((c) => (c.commentId === comment.commentId ? { ...c, dislikeCnt: c.dislikeCnt + 1 } : c)),
      );
    } catch (error) {
      console.error("Error disliking comment:", error);
    }
  };

  const isOwner = auth.user?.profile.email === comment.userId;

  return (
    <div style={styles.comment}>
      {isEditing ? (
        <>
          <textarea style={styles.textarea} value={editContent} onChange={(e) => setEditContent(e.target.value)} />
          <button style={styles.saveButton} onClick={handleUpdateComment}>
            Save
          </button>
          <button style={styles.cancelButton} onClick={() => setIsEditing(false)}>
            Cancel
          </button>
        </>
      ) : (
        <>
          <p style={styles.content}>{comment.content}</p>
          <p style={styles.meta}>{comment.date}</p>
          <p style={styles.meta}>
            By {comment.userId} - Likes: {comment.likeCnt} - Dislikes: {comment.dislikeCnt}
          </p>
          {isOwner && (
            <>
              <button style={styles.editButton} onClick={() => setIsEditing(true)}>
                Edit
              </button>
              <button style={styles.deleteButton} onClick={handleDeleteComment}>
                Delete
              </button>
            </>
          )}
          <button style={styles.likeButton} onClick={handleLike}>
            👍 Like
          </button>
          <button style={styles.dislikeButton} onClick={handleDislike}>
            👎 Dislike
          </button>
          {comment.replyCnt > 0 && (
            <button style={styles.replyToggle} onClick={handleToggleReplies}>
              {showReplies ? "Hide Replies" : "View Replies"}
            </button>
          )}
        </>
      )}
      {showReplies && replies && (
        <div style={styles.replies}>
          {replies.map((reply) => (
            <div key={reply.replyId} style={styles.reply}>
              <p style={styles.replyContent}>{reply.content}</p>
              <p style={styles.replyMeta}>
                By {reply.userId} - Likes: {reply.likeCnt} - Dislikes: {reply.dislikeCnt}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  comment: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "15px",
    marginBottom: "15px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  content: {
    fontSize: "16px",
    margin: "0",
  },
  meta: {
    fontSize: "12px",
    color: "#555",
    marginTop: "5px",
  },
  textarea: {
    width: "100%",
    height: "60px",
    marginBottom: "10px",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  editButton: {
    marginRight: "10px",
    padding: "5px 10px",
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteButton: {
    marginRight: "10px",
    padding: "5px 10px",
    backgroundColor: "#FF6347",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  likeButton: {
    marginRight: "10px",
    padding: "5px 10px",
    backgroundColor: "#28a745",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  dislikeButton: {
    marginRight: "10px",
    padding: "5px 10px",
    backgroundColor: "#FFC107",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  saveButton: {
    padding: "5px 10px",
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "5px 10px",
    backgroundColor: "#6c757d",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  replyToggle: {
    marginTop: "5px",
    cursor: "pointer",
    color: "#007BFF",
    background: "none",
    border: "none",
    textDecoration: "underline",
  },
  replies: {
    marginTop: "10px",
    marginLeft: "20px",
    paddingLeft: "10px",
    borderLeft: "2px solid #ddd",
    backgroundColor: "#ffffff",
  },
  reply: {
    marginBottom: "10px",
  },
  replyContent: {
    fontSize: "14px",
    margin: "0",
  },
  replyMeta: {
    fontSize: "12px",
    color: "#555",
    marginTop: "3px",
  },
};

export default Comment;
