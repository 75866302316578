import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import VideoPlayer from "./VideoPlayer";
import Comment from "./VideoAloneComponent/Comment";
import api from "../../ApiMap";
import { useAuth } from "react-oidc-context";

const VideoAlone = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [videoUrls, setVideoUrls] = useState({});
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState({});
  const [sortByLikes, setSortByLikes] = useState(true); // 댓글 정렬 기준
  const [page, setPage] = useState(0); // 현재 페이지
  const [end, setEnd] = useState(false); // 페이지 끝 여부
  const [size] = useState(10); // 페이지 크기
  const [newComment, setNewComment] = useState(""); // 새 댓글 내용

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await api.getVideoById(videoId);
        const videoData = response.data;

        setVideo(videoData);
        setLikes(videoData.likeCnt);
        setDislikes(videoData.dislikeCnt);

        const s3Response = await api.findS3Files({ path: videoData.convertUrl });
        const urls = {};
        s3Response.data.forEach((file) => {
          if (file.includes("1080p") && file.endsWith(".m3u8")) urls["1080p"] = file;
          if (file.includes("720p") && file.endsWith(".m3u8")) urls["720p"] = file;
          if (file.includes("480p") && file.endsWith(".m3u8")) urls["480p"] = file;
        });

        setVideoUrls(urls);
      } catch (error) {
        console.error("Error fetching video or S3 files:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
    fetchComments(0, sortByLikes); // 초기 댓글 로드
  }, [videoId, sortByLikes]);

  const fetchComments = async (currentPage, sortByLikes) => {
    try {
      const response = sortByLikes
        ? await api.getCommentsByLikes(videoId, currentPage, size)
        : await api.getCommentsByDate(videoId, currentPage, size);

      if (response.data) {
        const { content, end } = response.data;

        setComments((prev) => (currentPage === 0 ? content : [...prev, ...content]));
        setEnd(end);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const fetchReplies = async (commentId) => {
    if (replies[commentId]) return; // 이미 로드된 경우
    try {
      const response = await api.findRepliesByComment(commentId);
      if (response.data) {
        setReplies((prev) => ({
          ...prev,
          [commentId]: response.data.content, // 답글 리스트를 상태에 저장
        }));
      }
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  };

  const handleCreateComment = async () => {
    if (!newComment.trim()) return;

    try {
      const userId = auth.user?.profile.email;
      const response = await api.createComment({
        userId,
        videoId,
        content: newComment,
      });

      if (response.data) {
        setComments((prev) => [response.data, ...prev]); // 새 댓글 추가
        setNewComment("");
      }
    } catch (error) {
      console.error("Error creating comment:", error);
    }
  };

  const handleLoadMoreComments = () => {
    if (!end) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchComments(nextPage, sortByLikes);
    }
  };

  const handleLikeToggle = async () => {
    // todo : 좋아요 주작 가능
    try {
      if (isLiked) {
        // 좋아요 취소
        await api.updateVideoLikes(videoId, true, false);
        setLikes((prev) => prev - 1);
        setIsLiked(false);
      } else {
        // 좋아요 추가
        await api.updateVideoLikes(videoId, true, true);
        setLikes((prev) => prev + 1);
        setIsLiked(true);

        // 싫어요 활성화 상태 취소
        if (isDisliked) {
          await api.updateVideoLikes(videoId, false, false);
          setDislikes((prev) => prev - 1);
          setIsDisliked(false);
        }
      }
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleDislikeToggle = async () => {
    try {
      if (isDisliked) {
        // 싫어요 취소
        await api.updateVideoLikes(videoId, false, false);
        setDislikes((prev) => prev - 1);
        setIsDisliked(false);
      } else {
        // 싫어요 추가
        await api.updateVideoLikes(videoId, false, true);
        setDislikes((prev) => prev + 1);
        setIsDisliked(true);

        // 좋아요 활성화 상태 취소
        if (isLiked) {
          await api.updateVideoLikes(videoId, true, false);
          setLikes((prev) => prev - 1);
          setIsLiked(false);
        }
      }
    } catch (error) {
      console.error("Error toggling dislike:", error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (!video) return <p>Video not found</p>;

  return (
    <div style={styles.container}>
      <button
        style={styles.homeButton}
        onClick={() => navigate("/")} // 클릭 시 /로 이동
      >
        Go Back to Dashboard
      </button>
      <h1 style={styles.title}>{video.title}</h1>
      <VideoPlayer videoUrls={videoUrls} />
      <div style={styles.info}>
        <p>Uploaded by: {video.userName}</p>
        <p>Category: {video.categoryName}</p>
        <p>{video.description}</p>
        <p style={styles.stats}>
          <span style={isLiked ? styles.liked : styles.default} onClick={handleLikeToggle}>
            👍 {likes}
          </span>
          <span style={isDisliked ? styles.disliked : styles.default} onClick={handleDislikeToggle}>
            👎 {dislikes}
          </span>
          <span>👁️ {video.viewCnt}</span>
        </p>
      </div>
      <div>
        <h2>Comments</h2>
        <div style={styles.commentInput}>
          <textarea
            style={styles.textarea}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Write a comment..."
          />
          <button style={styles.createButton} onClick={handleCreateComment}>
            Add Comment
          </button>
        </div>
        <button
          style={styles.sortButton}
          onClick={() => {
            setSortByLikes(!sortByLikes); // 정렬 기준 변경
            setPage(0); // 페이지 초기화
            setEnd(false); // 페이지 끝 초기화
            fetchComments(0, !sortByLikes); // 새로운 정렬 기준으로 댓글 로드
          }}
        >
          Sort by: {sortByLikes ? "Date" : "Likes"}
        </button>
        {comments.map((comment) => (
          <Comment
            key={comment.commentId}
            comment={comment}
            fetchReplies={fetchReplies}
            replies={replies[comment.commentId]}
            auth={auth}
            setComments={setComments} // 상태 업데이트 함수 전달
          />
        ))}
        {!end && <button onClick={handleLoadMoreComments}>Load More</button>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  homeButton: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  info: {
    marginTop: "20px",
    fontSize: "16px",
    color: "#555",
  },
  stats: {
    display: "flex",
    gap: "15px",
    cursor: "pointer",
    fontSize: "18px",
  },
  liked: {
    color: "#007BFF",
  },
  disliked: {
    color: "#FF6347",
  },
  default: {
    color: "#333",
  },
  comments: {
    marginTop: "30px",
  },
  sortButton: {
    display: "inline-block",
    backgroundColor: "#007BFF",
    color: "#FFF",
    padding: "10px 20px",
    fontSize: "14px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  commentInput: {
    marginBottom: "20px",
  },
  textarea: {
    width: "100%",
    height: "60px",
    marginBottom: "10px",
    padding: "10px",
    fontSize: "14px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  createButton: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#FFF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default VideoAlone;
