import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../ApiMap";
import { useAuth } from "react-oidc-context";

const UserInfo = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [nickname, setNickname] = useState("");
  const [profileUrl, setProfileUrl] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.user) {
        const userId = auth.user?.profile.email.replace("@", "%40");
        const { data, error } = await api.getUser(userId);
        if (data) {
          setUserData(data);
          setNickname(data.nickname);
          setProfileUrl(data.profileUrl);
        } else {
          setError(error);
        }
      }
    };
    fetchUserData();
  }, [auth.user]);

  const handleNicknameUpdate = async () => {
    const dto = { userId: userData.userId, nickname };
    const { error } = await api.updateNickname(dto);
    if (!error) {
      alert("Nickname updated successfully!");
      setUserData({ ...userData, nickname });
    } else {
      alert(`Error updating nickname: ${error}`);
    }
  };

  const handleProfileUpdate = async () => {
    const dto = { userId: userData.userId, profileUrl };
    const { error } = await api.updateProfile(dto);
    if (!error) {
      alert("Profile updated successfully!");
      setUserData({ ...userData, profileUrl });
    } else {
      alert(`Error updating profile: ${error}`);
    }
  };

  const handleUserDeletion = async () => {
    if (window.confirm("진짜 지울거임?????")) {
      const { error } = await api.deleteUser(userData.userId);
      if (!error) {
        alert("User deleted successfully!");
        auth.signoutRedirect(); // 로그아웃 처리
      } else {
        alert(`Error deleting user: ${error}`);
      }
    }
  };

  if (!auth.user) return <p>Loading...</p>;

  return (
    <div style={styles.container}>
      <button style={styles.backButton} onClick={() => navigate("/dashboard")}>
        Go Back to Dashboard
      </button>
      {error ? (
        <p style={styles.errorMessage}>Error: {error}</p>
      ) : userData ? (
        <div style={styles.card}>
          <h2 style={styles.title}>User Info</h2>
          <p>User ID: {userData.userId}</p>
          <p>Join Date: {userData.date}</p>
          <p>Subscribers: {userData.subscribeCount}</p>
          <p>
            Profile:{" "}
            {userData.profileUrl !== "NA" ? (
              <img src={userData.profileUrl} alt="User Profile" style={styles.profileImage} />
            ) : (
              "No profile picture"
            )}
          </p>

          {/* 닉네임 수정 */}
          <div style={styles.formGroup}>
            <label>
              Nickname:
              <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} style={styles.input} />
            </label>
            <button style={styles.updateButton} onClick={handleNicknameUpdate}>
              Update Nickname
            </button>
          </div>

          {/* 프로필 URL 수정 */}
          <div style={styles.formGroup}>
            <label>
              Profile URL:
              <input
                type="text"
                value={profileUrl}
                onChange={(e) => setProfileUrl(e.target.value)}
                style={styles.input}
              />
            </label>
            <button style={styles.updateButton} onClick={handleProfileUpdate}>
              Update Profile
            </button>
          </div>

          {/* 회원 탈퇴 */}
          <div>
            <button style={styles.deleteButton} onClick={handleUserDeletion}>
              Delete Account
            </button>
          </div>
        </div>
      ) : (
        <p style={styles.loadingMessage}>Loading user data...</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  card: {
    textAlign: "left",
  },
  title: {
    fontSize: "24px",
    color: "#333",
    marginBottom: "20px",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  formGroup: {
    marginTop: "15px",
  },
  input: {
    width: "calc(100% - 20px)",
    padding: "8px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  updateButton: {
    backgroundColor: "#4caf50",
    color: "white",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  updateButtonHover: {
    backgroundColor: "#45a049",
  },
  deleteButton: {
    backgroundColor: "#f44336",
    color: "white",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  deleteButtonHover: {
    backgroundColor: "#d32f2f",
  },
  backButton: {
    backgroundColor: "#2196f3",
    color: "white",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  errorMessage: {
    color: "red",
  },
  loadingMessage: {
    fontStyle: "italic",
  },
};

export default UserInfo;
