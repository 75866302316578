import React, { useState } from "react";
import Search from "./Search";

const VideoPage = () => {
  const [videos, setVideos] = useState([]); // 검색 결과 비디오 데이터를 저장

  // 검색 결과를 처리하는 함수
  const handleSearchResults = (searchResults) => {
    setVideos(searchResults); // 검색된 비디오 데이터를 VideoList에 전달
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Search Your Favorite Video</h1>
      {/* 검색 컴포넌트 */}
      <Search onSearchResults={handleSearchResults} />
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  header: {
    textAlign: "center",
    fontSize: "24px",
    marginBottom: "20px",
  },
};

export default VideoPage;
