import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "react-oidc-context";

const cognitoAuthConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_FRONT_URL,
  response_type: "code",
  scope: "email openid phone",
  loadUserInfo: true,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider {...cognitoAuthConfig}>
    <App />
  </AuthProvider>,
);
