import React from "react";
import VideoNode from "./VideoNode";

const VideoList = ({ videos }) => {
  return (
    <div style={styles.listContainer}>
      <div style={styles.centeredContainer}>
        {videos.map((video) => (
          <VideoNode key={video.videoId} video={video} />
        ))}
      </div>
    </div>
  );
};

const styles = {
  listContainer: {
    display: "flex",
    justifyContent: "center", // 중앙 정렬
    alignItems: "center", // 세로 중앙 정렬
    width: "100%", // 전체 너비 사용
    padding: "20px",
  },
  centeredContainer: {
    display: "flex",
    flexWrap: "wrap", // 한 줄에 여러 개의 videoNode 배치
    gap: "20px",
    justifyContent: "center", // 중앙 정렬
  },
};

export default VideoList;
