import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const Profile = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Welcome to Chalkac</h2>
      <Button variant="primary" onClick={() => navigate("/userInfo")} style={styles.button}>
        Go to User Info
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#007BFF",
  },
  button: {
    padding: "12px 20px",
    fontSize: "16px",
    borderRadius: "4px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
};

export default Profile;
