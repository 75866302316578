import React from "react";
import { useAuth } from "react-oidc-context";
import { Button } from "react-bootstrap"; // react-bootstrap을 사용하여 버튼 스타일링
import logo from "./ChalkacLogo.webp";

const Auth = () => {
  const auth = useAuth();

  // 로그인 처리 함수
  const handleLogin = async () => {
    try {
      await auth.signinRedirect(); // Cognito 로그인 페이지로 리다이렉트
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  // 로딩 상태일 때
  if (auth.isLoading) {
    return <div style={styles.loading}>Loading...</div>;
  }

  // 에러 발생 시
  if (auth.error) {
    return (
      <div style={styles.error}>
        <p>Error: {auth.error.message}</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div className="logoContainer">
        <img src={logo} alt="Chalkac Logo" className="logo" width="200px" /> {/* logo 클래스 적용 */}
      </div>
      <div style={styles.content}>
        <h1 style={styles.title}>Please Login</h1>
        <Button variant="primary" onClick={handleLogin} style={styles.loginButton}>
          Login with Cognito
        </Button>
      </div>
    </div>
  );
};

// 스타일 객체
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    fontFamily: "Arial, sans-serif",
    flexDirection: "column",
  },
  content: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    width: "300px",
  },
  title: {
    marginBottom: "20px",
    fontSize: "24px",
    color: "#333",
  },
  loginButton: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  loading: {
    fontSize: "18px",
    color: "#007BFF",
  },
  error: {
    fontSize: "18px",
    color: "red",
  },
};

export default Auth;
