import React, { useState, useEffect } from "react";
import api from "../../ApiMap"; // api.js를 import
import { useAuth } from "react-oidc-context";
import StateModal from "./StateModal";
import { Button } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

const VideoUpload = () => {
  const auth = useAuth();

  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [jobId, setJobId] = useState("");
  const [isCheckingStatus, setIsCheckingStatus] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const s3Config = {
    region: "ap-northeast-2", // 리전 설정
    bucketName: process.env.REACT_APP_BUCKET_NAME, // S3 버킷 이름
    identityPoolId: process.env.REACT_APP_ID_POOL, // 교체 필요
  };

  const s3Client = new S3Client({
    region: s3Config.region,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: s3Config.region },
      identityPoolId: s3Config.identityPoolId,
    }),
  });

  const handleFileChange = (e) => {
    if (e.target.name === "video") {
      setVideoFile(e.target.files[0]);
    } else if (e.target.name === "thumbnail") {
      setThumbnailFile(e.target.files[0]);
    }
  };

  const uploadToS3 = async (file, folder) => {
    const params = {
      Bucket: s3Config.bucketName, // 업로드할 S3 버킷 이름
      Key: `${folder}/${file.name}`, // 업로드 경로 및 파일명
      Body: file, // 업로드할 파일의 내용
      ContentType: file.type, // 파일의 Content-Type (ex. image/png, video/mp4 등)
    };

    try {
      const command = new PutObjectCommand(params);
      const data = await s3Client.send(command);
      return `https://${s3Config.bucketName}.s3.${s3Config.region}.amazonaws.com/${folder}/${file.name}`;
    } catch (error) {
      console.error(`Error uploading ${file.name}:`, error);
      throw error;
    }
  };

  const handleUpload = async () => {
    const options = {
      maxSizeMB: 1, // 최대 이미지 크기 (MB)
      maxWidthOrHeight: 1920, // 최대 이미지 너비/높이
      useWebWorker: true,
      fileType: "image/webp", // WebP 형식으로 변환
    };

    setShow(true);
    if (!videoFile || !thumbnailFile) {
      alert("Please select both video and thumbnail files!");
      return;
    }

    try {
      setUploadStatus("Uploading files...");
      const compressedFile = await imageCompression(thumbnailFile, options);
      // Upload video and thumbnail to S3
      const videoHttpUrl = await uploadToS3(videoFile, "videos");
      const thumbnailHttpUrl = await uploadToS3(compressedFile, "thumbnails");

      // Convert HTTP URL to S3 URI
      const videoS3Url = videoHttpUrl.replace(
        "https://chalkac-video-bucket.s3.ap-northeast-2.amazonaws.com/",
        "s3://chalkac-video-bucket/",
      );

      setUploadStatus("Files uploaded to S3. Sending metadata to backend...");

      // Send metadata to backend using api.js
      const metaDataResponse = await api.createVideo({
        categoryId: "1",
        userId: auth.user?.profile.email,
        originalUrl: videoHttpUrl,
        convertUrl: "-",
        thumbnailUrl: thumbnailHttpUrl,
        likeCnt: 0,
        dislikeCnt: 0,
        viewCnt: 0,
        description: description,
        title: title,
      });

      if (metaDataResponse.error) {
        setUploadStatus("Error saving metadata to backend. Title already exists or BadRequest");
        return;
      }

      setUploadStatus("Metadata saved. Starting MediaConvert job...");
      setVideoId(metaDataResponse.data.videoId);

      // Start MediaConvert job
      const convertResponse = await api.convertVideo({
        inputS3Url: videoS3Url,
        outputS3Url: `s3://chalkac-video-bucket/output-folder/${title}/`, // todo : 제목 중복 문제
      });

      if (convertResponse.error) {
        setUploadStatus("Error starting MediaConvert job.");
        return;
      }

      setUploadStatus("MediaConvert job started successfully!");
      setJobId(convertResponse.data.jobId);
      setIsCheckingStatus(true);
    } catch (error) {
      console.error("Error during upload and processing:", error);
      setUploadStatus("Upload failed!");
    }
  };

  useEffect(() => {
    let interval;

    const checkJobStatus = async () => {
      try {
        const statusResponse = await api.getConvertStatus({ jobId: jobId });

        if (statusResponse.error) {
          setUploadStatus("Error checking job status.");
          return;
        }

        const status = statusResponse.data;
        setUploadStatus(`Job Status: ${status}`);

        if (status === "COMPLETE") {
          clearInterval(interval);
          setIsCheckingStatus(false);
          setUploadStatus("Job complete! Saving converted URL...");

          // Save converted URL
          const saveResponse = api.saveConvertUrlVideo({
            jobId: jobId,
            videoId: videoId,
          });

          if (saveResponse.error) {
            setUploadStatus("Error saving converted URL.");
            return;
          }

          setUploadStatus("Conversion complete and URL saved!");
          setJobId("");
          setVideoId("");
        } else if (status === "ERROR") {
          clearInterval(interval);
          setIsCheckingStatus(false);
          setUploadStatus("Job failed. Please retry.");
        }
      } catch (error) {
        console.error("Error checking job status:", error);
        setUploadStatus("Error checking job status.");
      }
    };

    if (isCheckingStatus && jobId) {
      interval = setInterval(checkJobStatus, 1000);
    }

    return () => clearInterval(interval);
  }, [isCheckingStatus, jobId]);

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Upload Video and Thumbnail</h1>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Video File:
          <input type="file" name="video" accept="video/mp4" onChange={handleFileChange} style={styles.fileInput} />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>
          Thumbnail File:
          <input type="file" name="thumbnail" accept="image/*" onChange={handleFileChange} style={styles.fileInput} />
        </label>
      </div>
      <div style={styles.inputGroup}>
        <label htmlFor="title" style={styles.label}>
          Title:
        </label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter video title"
          style={styles.textInput}
        />
      </div>
      <div style={styles.inputGroup}>
        <label htmlFor="description" style={styles.label}>
          Description:
        </label>
        <textarea
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Enter video description"
          style={styles.textarea}
        />
      </div>
      <Button onClick={handleUpload} style={styles.uploadButton}>
        Upload
      </Button>
      <StateModal show={show} handleClose={handleClose} uploadStatus={uploadStatus} />
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
    color: "#333",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    fontWeight: "bold",
    marginBottom: "5px",
    color: "#555",
  },
  fileInput: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  textInput: {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  textarea: {
    width: "100%",
    height: "80px",
    padding: "10px",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  uploadButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    backgroundColor: "#007BFF",
    color: "#FFF",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },
};

export default VideoUpload;
