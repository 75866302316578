import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";

const StateModal = ({ show, handleClose, uploadStatus }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    setIsSuccess(uploadStatus === "Conversion complete and URL saved!" ? true : false);
    switch (uploadStatus) {
      case "Uploading files...":
        setPercent(5);
        break;
      case "Files uploaded to S3. Sending metadata to backend...":
        setPercent(15);
        break;
      case "Error saving metadata to backend.":
        setPercent(0);
        break;
      case "Metadata saved. Starting MediaConvert job...":
        setPercent(25);
        break;
      case "Error starting MediaConvert job.":
        setPercent(0);
        break;
      case "MediaConvert job started successfully!":
        setPercent(50);
        break;
      case "Job Status: SUBMITTED":
        setPercent(60);
        break;
      case "Job Status: PROGRESSING":
        setPercent(75);
        break;
      case "Job Status: COMPLETE":
      case "Job complete! Saving converted URL...":
        setPercent(90);
        break;
      case "Conversion complete and URL saved!":
        setPercent(100);
        break;
      case "Error checking job status.":
      case "Error saving converted URL.":
      case "Job failed. Please retry.":
      case "Upload failed!":
      default:
        setPercent(0);
        break;
    }
  }, [uploadStatus]);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>영상 업로드 중...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{uploadStatus}</p>
          <ProgressBar animated now={percent} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" size="lg" onClick={handleClose} disabled={!isSuccess}>
            {percent === 100 ? (
              <span>OK</span>
            ) : (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StateModal;
