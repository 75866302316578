import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const Logout = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const accessToken = auth.user?.access_token;
      const refreshToken = auth.user?.refresh_token;

      if (accessToken && refreshToken) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
      }
    };

    handleCallback();
  }, [auth, navigate]);

  const handleSignOut = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const logoutUri = process.env.REACT_APP_FRONT_URL;
    const cognitoDomain = process.env.REACT_APP_DOMAIN_URL;

    // 로컬 스토리지 정리 및 Cognito 로그아웃
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    auth.removeUser(); // oidc-context 상태 초기화
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  return (
    <div>
      <Button onClick={handleSignOut}>Sign Out</Button>
    </div>
  );
};

export default Logout;
