import React, { useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoPlayer = ({ videoUrls }) => {
  const [currentQuality, setCurrentQuality] = useState("1080p"); // 기본 화질 설정
  const [renderKey, setRenderKey] = useState(Date.now()); // DOM 재생성용 키

  // 화질 URL을 동적으로 변경
  const handleQualityChange = (quality) => {
    setCurrentQuality(quality);
    setRenderKey(Date.now()); // 새로운 키 설정으로 DOM 재생성
  };

  const options = {
    controls: true,
    autoplay: true,
    preload: "auto",
    responsive: true,
    fluid: true,
    loop: true,
    sources: [
      {
        src: videoUrls[currentQuality],
        type: "application/x-mpegURL", // m3u8 타입
      },
    ],
  };

  return (
    <div>
      <div style={styles.qualitySelector}>
        {Object.keys(videoUrls).map((quality) => (
          <button
            key={quality}
            onClick={() => handleQualityChange(quality)}
            style={currentQuality === quality ? { ...styles.button, ...styles.activeButton } : styles.button}
          >
            {quality}
          </button>
        ))}
      </div>
      {/* renderKey 변경으로 DOM 재생성 */}
      <VideoJS key={renderKey} options={options} />
    </div>
  );
};

// VideoJS 컴포넌트
const VideoJS = ({ options }) => {
  const placeholderRef = React.useRef(null);
  const playerRef = React.useRef(null);

  React.useEffect(() => {
    // 기존 플레이어가 없으면 새로 초기화
    if (!playerRef.current) {
      const placeholderEl = placeholderRef.current;
      const videoElement = placeholderEl.appendChild(document.createElement("video-js"));

      const player = (playerRef.current = videojs(videoElement, options, () => {
        player.log("player is ready");
      }));
    } else {
      // 플레이어 업데이트
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }

    return () => {
      // 컴포넌트 언마운트 시 플레이어 제거
      const player = playerRef.current;
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [options]);

  return <div ref={placeholderRef}></div>;
};

const styles = {
  qualitySelector: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  button: {
    padding: "10px",
    margin: "5px",
    border: "1px solid #007BFF",
    borderRadius: "4px",
    backgroundColor: "white",
    cursor: "pointer",
    fontSize: "14px",
    color: "#007BFF",
  },
  activeButton: {
    backgroundColor: "#007BFF",
    color: "white",
  },
};

export default VideoPlayer;
