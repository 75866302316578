import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Swagger의 기본 URL로 설정
  timeout: 5000, // 요청 제한 시간 설정
  headers: {
    "Content-Type": "application/json",
  },
});

// 요청 인터셉터
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken"); // Access Token 가져오기
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const handleApiResponse = async (apiCall) => {
  try {
    const response = await apiCall();
    return { data: response.data, error: null }; // 성공 시 데이터 반환
  } catch (error) {
    if (error.response?.status === 401) {
      console.error("Unauthorized! Redirecting to login.");
      // 매개변수로 auth를 넣어야 함 auth.signoutRedirect();
    }
    const errorMessage = error.response?.data?.message || "Unknown error occurred";
    return { data: null, error: errorMessage }; // 실패 시 에러 메시지 반환
  }
};

const api = {
  getUser: async (userId) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/users/${userId}`));
  },

  updateNickname: async (dto) => {
    return await handleApiResponse(() => apiClient.patch(`/api/v1/users/nickname`, dto));
  },

  updateProfile: async (dto) => {
    return await handleApiResponse(() => apiClient.patch(`/api/v1/users/profile`, dto));
  },

  deleteUser: async (userId) => {
    return await handleApiResponse(() => apiClient.delete(`/api/v1/users/${userId}`));
  },

  // 카테고리
  // 1. 전체 조회
  getAllCategories: async () => {
    return await handleApiResponse(() => apiClient.get("/api/v1/category"));
  },

  // 2. 개별 조회
  getCategoryById: async (id) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/category/${id}`));
  },

  // 3. 생성 (관리자 권한 필요)
  createCategory: async (dto) => {
    return await handleApiResponse(() => apiClient.post("/api/v1/category", dto));
  },

  // 4. 삭제 (관리자 권한 필요)
  deleteCategoryById: async (id) => {
    return await handleApiResponse(() => apiClient.delete(`/api/v1/category/${id}`));
  },

  // 영상
  // 1. 검색 (페이징 처리)
  searchVideos: async (keyword = "", page = 0, size = 10) => {
    return await handleApiResponse(() =>
      apiClient.get(`/api/v1/video/search`, {
        params: { keyword, page, size },
      }),
    );
  },

  // 유저의 ID로 영상 찾기
  getVideosByUser: async (page = 0, size = 10, userId) => {
    return await handleApiResponse(() =>
      apiClient.get(`/api/v1/video/users`, {
        params: { page, size, userId },
      }),
    );
  },

  // 2. 미디어 변환
  convertVideo: async (dto) => {
    return await handleApiResponse(() => apiClient.post(`/api/v1/video/convert`, dto));
  },

  // 3. 변환 상태 조회
  getConvertStatus: async (dto) => {
    return await handleApiResponse(() => apiClient.post(`/api/v1/video/status`, dto));
  },

  // 4. 변환 URL 저장
  saveConvertUrlVideo: async (dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/convertsave`, dto));
  },

  // 5. S3 파일 조회
  findS3Files: async (dto) => {
    return await handleApiResponse(() => apiClient.post(`/api/v1/video/findS3files`, dto));
  },

  // 6. 모든 영상 조회 (페이징 처리)
  getAllVideos: async (page, size) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/video`, { params: { page, size } }));
  },

  // 7. 단일 영상 조회
  getVideoById: async (id) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/video/${id}`));
  },

  // 8. 영상 업로드
  createVideo: (dto) => {
    return handleApiResponse(() => apiClient.post(`/api/v1/video`, dto));
  },

  // 9. 카테고리 수정
  updateVideoCategory: async (dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/category`, dto));
  },

  // 10. 썸네일 수정
  updateVideoThumbnail: async (dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/thumbnail`, dto));
  },

  // 11. 제목 수정
  updateVideoTitle: async (dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/title`, dto));
  },

  // 12. 설명 수정
  updateVideoDescription: async (dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/description`, dto));
  },

  // 13. 좋아요/싫어요 수정
  updateVideoLikes: async (id, isLike, isIncrement) => {
    return await handleApiResponse(() =>
      apiClient.put(`/api/v1/video/${id}/like`, null, {
        params: { isLike, isIncrement },
      }),
    );
  },

  // 14. 조회수 증가
  incrementViewCount: async (id) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/video/${id}/view`));
  },

  // 15. 영상 삭제
  deleteVideo: async (id) => {
    return await handleApiResponse(() => apiClient.delete(`/api/v1/video/${id}`));
  },

  // 댓글
  // 1. 해당 비디오의 댓글 조회 - 좋아요 수 높은 순
  getCommentsByLikes: async (videoId, page = 0, size = 10) => {
    return await handleApiResponse(() =>
      apiClient.get(`/api/v1/comment/${videoId}/by-likes`, {
        params: { page, size },
      }),
    );
  },

  // 2. 해당 비디오의 댓글 조회 - 최신 순
  getCommentsByDate: async (videoId, page = 0, size = 10) => {
    return await handleApiResponse(() =>
      apiClient.get(`/api/v1/comment/${videoId}/by-date`, {
        params: { page, size },
      }),
    );
  },

  // 3. ID로 댓글 하나 조회
  getCommentById: async (commentId) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/comment/${commentId}`));
  },

  // 4. 댓글 생성
  createComment: async (dto) => {
    return await handleApiResponse(() => apiClient.post(`/api/v1/comment`, dto));
  },

  // 5. 댓글 수정
  updateComment: async (commentId, dto) => {
    return await handleApiResponse(() => apiClient.put(`/api/v1/comment/${commentId}`, dto));
  },

  // 6. 댓글 삭제
  deleteComment: async (commentId) => {
    return await handleApiResponse(() => apiClient.delete(`/api/v1/comment/${commentId}`));
  },

  // 7. 좋아요 수 증가/감소
  updateLikeCount: async (commentId, increment) => {
    return await handleApiResponse(() =>
      apiClient.patch(`/api/v1/comment/${commentId}/like`, null, {
        params: { increment },
      }),
    );
  },

  // 8. 싫어요 수 증가/감소
  updateDislikeCount: async (commentId, increment) => {
    return await handleApiResponse(() =>
      apiClient.patch(`/api/v1/comment/${commentId}/dislike`, null, {
        params: { increment },
      }),
    );
  },

  // 9. 댓글 reply count 증가
  incrementReplyCount: async (commentId) => {
    return await handleApiResponse(() => apiClient.patch(`/api/v1/comment/${commentId}/reply`));
  },

  // 답글
  // 1. 해당 댓글의 답글 조회 - 최신순
  findRepliesByComment: async (commentId, page = 0, size = 10) => {
    return await handleApiResponse(() =>
      apiClient.get(`/api/v1/reply/${commentId}/by-date`, {
        params: { page, size },
      }),
    );
  },

  // 2. 답글 하나 조회
  findReplyById: async (replyId) => {
    return await handleApiResponse(() => apiClient.get(`/api/v1/reply/${replyId}`));
  },

  // 3. 답글 추가
  createReply: async (dto) => {
    return await handleApiResponse(() => apiClient.post(`/api/v1/reply`, dto));
  },

  // 4. 답글 삭제
  deleteReply: async (replyId) => {
    return await handleApiResponse(() => apiClient.delete(`/api/v1/reply/${replyId}`));
  },

  // 5. 답글 좋아요 수 증가/감소
  updateReplyLikeCount: async (replyId, increment) => {
    return await handleApiResponse(() =>
      apiClient.patch(`/api/v1/reply/${replyId}/like`, null, {
        params: { increment },
      }),
    );
  },

  // 6. 답글 싫어요 수 증가/감소
  updateReplyDislikeCount: async (replyId, increment) => {
    return await handleApiResponse(() =>
      apiClient.patch(`/api/v1/reply/${replyId}/dislike`, null, {
        params: { increment },
      }),
    );
  },
};

export default api;
