import React, { useState } from "react";
import Logout from "./Auth/Logout";
import Profile from "./User/Profile";
import VideoUpload from "./VideoUpload/VideoUpload";
import VideoPage from "./VideoSerach/VideoPage";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaBars } from "react-icons/fa"; // FontAwesome 햄버거 아이콘

const Dashboard = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <div>
      {/* 햄버거 버튼과 로그아웃 버튼을 가로로 배치 */}
      <div style={styles.header}>
        <button variant="light" onClick={toggleShow} className="me-2" style={styles.hamburgerButton}>
          <FaBars /> {/* 햄버거 아이콘 */}
        </button>
        <Logout />
      </div>

      {/* Offcanvas 메뉴 */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Chalkac</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Profile />
          <hr />
          <VideoUpload />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Video Page */}
      <VideoPage />
    </div>
  );
};

// 스타일 객체
const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between", // 양 끝에 버튼 배치
    alignItems: "center",
    padding: "10px 20px",
    margin: "0 10%", // 양 옆에 10% 마진 추가
    backgroundColor: "#fff",
    borderBottom: "1px solid #ddd",
  },
  hamburgerButton: {
    fontSize: "24px",
    padding: "8px 12px",
    color: "#007BFF",
    border: "none",
    cursor: "pointer",
  },
};

export default Dashboard;
